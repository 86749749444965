import { Title } from '@solidjs/meta';
import { Button, Heading, Link } from '@troon/ui';
import { NoHydration } from 'solid-js/web';
import { Content } from '../../../components/content';

export default function CareersPage() {
	return (
		<NoHydration>
			<Title>Career opportunities | Troon</Title>

			<Content>
				<Heading as="h1">Career opportunities at Troon</Heading>

				<Button
					class="w-fit"
					as={Link}
					href="https://recruiting2.ultipro.com/TRO1001TROO/JobBoard/2b13054b-60bb-410d-9136-e52c1c7d9720/?q=&o=postedDateDesc"
				>
					Find jobs with Troon Worldwide
				</Button>
			</Content>
		</NoHydration>
	);
}
